// src/components/ServerInfo.js

import React, { useState } from 'react';
import './ServerInfo.css';

const ServerInfo = () => {
  const [copied, setCopied] = useState(false);
  const serverIP = 'play.orangeevents.net';

  const handleCopyIP = () => {
    navigator.clipboard.writeText(serverIP);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset "Copied!" message after 2 seconds
  };

  return (
    <section className="server-info">
      <h3 className="server-info-title">Server IP</h3>
      <p className="server-info-ip" onClick={handleCopyIP}>
        <strong>{serverIP}</strong>
      </p>
      <button className="copy-button" onClick={handleCopyIP}>
        {copied ? 'Copied!' : 'Copy IP'}
      </button>
    </section>
  );
};

export default ServerInfo;
