// src/components/Hero.js

import React from 'react';
import { motion } from 'framer-motion';
import './Hero.css';
import heroBg from '../images/hero-bg.png';

const Hero = () => {
  const handleJoinClick = () => {
    window.location.href = 'https://discord.orangeevents.net';
  };

  return (
    <motion.section
      className="hero bg-cover bg-center h-screen flex flex-col justify-center items-center text-center px-4"
      style={{ backgroundImage: `url(${heroBg})` }}
      id="home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.h2
        className="text-4xl md:text-6xl font-bold text-orange-500 mb-4"
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ duration: 1 }}
      >
        Welcome to Orange Events!
      </motion.h2>
      <motion.p
        className="text-lg md:text-xl mb-6 max-w-2xl leading-relaxed text-white bg-orange-700 bg-opacity-70 p-4 rounded-lg shadow-lg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <strong className="text-orange-300">Join our vibrant Minecraft community</strong> where <em>exciting events await.</em> Collaborate, compete, and create unforgettable memories with fellow gamers.
      </motion.p>
      <motion.button
        className="join-btn bg-orange-500 text-gray-800 py-3 px-8 rounded-lg hover:bg-orange-600 transform transition-transform duration-300 hover:scale-105 shadow-lg focus:outline-none focus:ring-2 focus:ring-orange-700"
        onClick={handleJoinClick}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <i className="fas fa-arrow-right mr-2"></i> Join Now!
      </motion.button>
    </motion.section>
  );
};

export default Hero;
