import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';

const Header = ({ isAuthenticated, onLogout }) => {
  const [navActive, setNavActive] = useState(false);
  const navigate = useNavigate();

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  // Smooth scroll function for in-page links
  const scrollToSection = (id) => {
    const section = document.querySelector(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setNavActive(false);
    }
  };

  return (
    <header className="bg-gray-800 fixed w-full top-0 z-50 shadow-md">
      <div className="container mx-auto flex justify-between items-center p-4">
        <h1
          className="text-2xl font-bold text-orange-500 cursor-pointer"
          onClick={() => navigate('/')}
        >
          Orange Events
        </h1>
        <nav>
          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button onClick={toggleNav} className="text-white focus:outline-none hamburger">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                {navActive ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                )}
              </svg>
            </button>
          </div>

          {/* Mobile Menu */}
          <Transition
            show={navActive}
            enter="transition ease-out duration-200 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-150 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <ul
                ref={ref}
                className="absolute top-16 right-0 w-48 bg-gray-800 rounded-md shadow-lg py-2 mt-2 space-y-2 md:space-y-0 md:flex md:space-x-6 md:static md:w-auto md:bg-transparent nav-links"
              >
                <li>
                  <button
                    onClick={() => scrollToSection('#events')}
                    className="block px-4 py-2 text-white hover:text-orange-500"
                  >
                    Events
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection('#store')}
                    className="block px-4 py-2 text-white hover:text-orange-500"
                  >
                    Store
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection('#discord')}
                    className="block px-4 py-2 text-white hover:text-orange-500"
                  >
                    Discord
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection('#contact')}
                    className="block px-4 py-2 text-white hover:text-orange-500"
                  >
                    Contact
                  </button>
                </li>
                {isAuthenticated ? (
                  <li>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-white hover:text-orange-500"
                    >
                      Logout
                    </button>
                  </li>
                ) : (
                  <li>
                    <Link
                      to="/login"
                      className="block px-4 py-2 text-white hover:text-orange-500"
                      onClick={() => setNavActive(false)}
                    >
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            )}
          </Transition>

          {/* Desktop Menu */}
          <ul className="hidden md:flex space-x-6 nav-links">
            <li>
              <button onClick={() => scrollToSection('#events')} className="text-white hover:text-orange-500">
                Events
              </button>
            </li>
            <li>
              <button onClick={() => scrollToSection('#store')} className="text-white hover:text-orange-500">
                Store
              </button>
            </li>
            <li>
              <button onClick={() => scrollToSection('#discord')} className="text-white hover:text-orange-500">
                Discord
              </button>
            </li>
            <li>
              <button onClick={() => scrollToSection('#contact')} className="text-white hover:text-orange-500">
                Contact
              </button>
            </li>
            {isAuthenticated ? (
              <li>
                <button
                  onClick={handleLogout}
                  className="text-white hover:text-orange-500 focus:outline-none"
                >
                  Logout
                </button>
              </li>
            ) : (
              <li>
                <Link to="/login" className="text-white hover:text-orange-500">
                  Login
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
