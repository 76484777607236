// src/components/Events.js

import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import './Events.css';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [category, setCategory] = useState('Upcoming'); // Default category
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/events`;

  useEffect(() => {
    fetchEvents(category);
  }, [category]);

  const fetchEvents = async (category) => {
    setLoading(true);
    setError('');
    try {
      const res = await fetch(`${BASE_URL}/category/${category}`);
      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.error || `Failed to fetch ${category} events`);
      }
      const data = await res.json();

      if (!Array.isArray(data)) throw new Error('Unexpected response format: Expected an array');

      setEvents(data);
    } catch (err) {
      console.error(`Failed to fetch ${category} events:`, err);
      setError(`Failed to fetch ${category} events. Please try again later.`);
      setEvents([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="events-container bg-gray-900 min-h-screen p-8 text-white">
      <h2 className="text-4xl font-bold mb-10 text-center text-orange-500">Events</h2>

      {/* Category Selection */}
      <div className="mb-10 flex justify-center gap-4">
        {['Ongoing', 'Previous', 'Upcoming'].map((tab) => (
          <button
            key={tab}
            className={`tab-button ${category === tab ? 'active' : ''}`}
            onClick={() => setCategory(tab)}
          >
            {tab} Events
          </button>
        ))}
      </div>

      {/* Events List */}
      {loading ? (
        <p className="text-gray-400 text-center">Loading events...</p>
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : (
        <div className="events-grid">
          {events.length > 0 ? (
            events.map((event) => (
              <div key={event._id} className="event-card">
                {event.image ? (
                  <img
                    src={event.image}
                    alt={event.title}
                    className="event-image"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = 'https://via.placeholder.com/300x200?text=No+Image';
                    }}
                  />
                ) : (
                  <div className="no-image">No Image</div>
                )}
                <div className="event-content">
                  <h3 className="event-title">{event.title || 'N/A'}</h3>
                  <p className="event-description">
                    {event.description && event.description.length > 100
                      ? `${event.description.substring(0, 100)}...`
                      : event.description || 'No Description'}
                  </p>
                  <div className="event-details">
                    <p>
                      <span className="detail-label">Start:</span>{' '}
                      {event.startDate ? format(new Date(event.startDate), 'dd/MM/yyyy') : 'N/A'}
                    </p>
                    <p>
                      <span className="detail-label">End:</span>{' '}
                      {event.endDate ? format(new Date(event.endDate), 'dd/MM/yyyy') : 'N/A'}
                    </p>
                    <p>
                      <span className="detail-label">Category:</span> {event.category || 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="no-events-message">No events available.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Events;
