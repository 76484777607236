// src/components/Login.js

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Login = ({ onAuth }) => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Destructure username and password for easier access
  const { username, password } = credentials;

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Basic validation
    if (!username || !password) {
      setError('Please enter both username and password.');
      return;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      const data = await res.json();

      if (res.ok) {
        // Save token to localStorage
        localStorage.setItem('token', data.token);
        onAuth(true);

        // Redirect to the desired page or admin dashboard
        const { from } = location.state || { from: { pathname: '/admin' } };
        navigate(from, { replace: true });
      } else {
        setError(data.error || 'Login failed.');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <form
        onSubmit={handleSubmit}
        className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md"
      >
        <h2 className="text-2xl text-center text-orange-500 mb-6">Admin Login</h2>
        {error && <p className="mb-4 text-red-500">{error}</p>}
        <div className="mb-4">
          <label className="block mb-2 text-white">Username</label>
          <input
            type="text"
            name="username"
            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-orange-500"
            value={username}
            onChange={handleChange}
            placeholder="Enter username"
            required
          />
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-white">Password</label>
          <input
            type="password"
            name="password"
            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-orange-500"
            value={password}
            onChange={handleChange}
            placeholder="Enter password"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-orange-500 text-gray-800 py-3 rounded hover:bg-orange-600 transition-colors duration-300"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
