// src/components/Contact.js

import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [responseMessage, setResponseMessage] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY; // Add your reCAPTCHA site key in the .env file

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.message) {
      setResponseMessage('Please fill in all fields.');
      return;
    }

    if (!captchaValue) {
      setResponseMessage('Please complete the CAPTCHA.');
      return;
    }

    try {
      const res = await fetch(`${BASE_URL}/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...formData, captcha: captchaValue })
      });

      const data = await res.json();

      if (res.ok) {
        setResponseMessage(data.message);
        setFormData({ name: '', email: '', message: '' });
        setCaptchaValue(null); // Reset CAPTCHA
      } else {
        setResponseMessage('An error occurred. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setResponseMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <section className="contact" id="contact">
      <h3>Contact Us</h3>
      <p className="contact-description">Feel free to reach out with any questions or feedback!</p>
      <form id="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            id="message"
            name="message"
            placeholder="Your Message"
            rows="5"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={handleCaptchaChange}
          />
        </div>
        <button type="submit" className="contact-btn">Send Message</button>
      </form>
      {responseMessage && <div id="form-response">{responseMessage}</div>}
    </section>
  );
};

export default Contact;
