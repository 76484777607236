// src/components/Admin.js

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const Admin = () => {
  const [events, setEvents] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    startDate: null,
    endDate: null,
    image: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [currentEventId, setCurrentEventId] = useState(null);
  const [message, setMessage] = useState('');
  const [activeTab, setActiveTab] = useState('Ongoing'); // 'Ongoing', 'Previous', 'Upcoming'
  const [loading, setLoading] = useState(false);

  // Base URL from environment variable with a trailing slash
  const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/events/`;

  useEffect(() => {
    fetchEvents(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  /**
   * Fetch events based on the selected category.
   * @param {string} category - The category of events to fetch.
   */
  const fetchEvents = async (category) => {
    setLoading(true);
    setMessage('');

    try {
      const res = await fetch(`${BASE_URL}category/${category}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Public routes do not require Authorization header
        },
      });

      const data = await res.json();

      if (res.ok) {
        if (Array.isArray(data)) {
          setEvents(data);
        } else {
          throw new Error('Unexpected response format: Expected an array');
        }
      } else {
        throw new Error(data.error || `Failed to fetch ${category} events`);
      }
    } catch (err) {
      console.error(`Failed to fetch ${category} events:`, err);
      setMessage(`Failed to fetch ${category} events. Please try again later.`);
      setEvents([]);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle changes in the form inputs.
   * @param {object} e - The event object.
   */
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * Handle changes in the start date picker.
   * @param {Date} date - The selected start date.
   */
  const handleStartDateChange = (date) => {
    setFormData({ ...formData, startDate: date });
  };

  /**
   * Handle changes in the end date picker.
   * @param {Date} date - The selected end date.
   */
  const handleEndDateChange = (date) => {
    setFormData({ ...formData, endDate: date });
  };

  /**
   * Handle form submission for creating or updating an event.
   * @param {object} e - The event object.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    const { title, description, startDate, endDate, image } = formData;

    // Basic validation
    if (!title || !description || !startDate || !endDate || !image) {
      setMessage('Please fill in all fields.');
      return;
    }

    // Ensure startDate <= endDate
    if (startDate > endDate) {
      setMessage('Start date cannot be after end date.');
      return;
    }

    // Format dates as yyyy-MM-dd for backend
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    // Retrieve the token from localStorage
    const token = localStorage.getItem('token');

    if (!token) {
      setMessage('You must be logged in to perform this action.');
      return;
    }

    try {
      const res = await fetch(
        isEditing ? `${BASE_URL}${currentEventId}` : BASE_URL,
        {
          method: isEditing ? 'PUT' : 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token here
          },
          body: JSON.stringify({
            title,
            description,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            image,
          }),
        }
      );

      const data = await res.json();

      if (res.ok) {
        setMessage(
          isEditing ? 'Event updated successfully.' : 'Event added successfully.'
        );
        // Refresh events list
        fetchEvents(activeTab);
        // Reset form
        setFormData({
          title: '',
          description: '',
          startDate: null,
          endDate: null,
          image: '',
        });
        setIsEditing(false);
        setCurrentEventId(null);
      } else {
        setMessage(data.error || 'Failed to submit the event.');
      }
    } catch (err) {
      console.error('Error submitting the event:', err);
      setMessage('An error occurred. Please try again.');
    }
  };

  /**
   * Handle the click event for editing an existing event.
   * @param {object} event - The event object to edit.
   */
  const handleEditClick = (event) => {
    setIsEditing(true);
    setCurrentEventId(event._id);
    setFormData({
      title: event.title,
      description: event.description,
      startDate: new Date(event.startDate),
      endDate: new Date(event.endDate),
      image: event.image,
    });
    setMessage('');
  };

  /**
   * Handle the deletion of an event.
   * @param {string} id - The ID of the event to delete.
   */
  const handleDeleteEvent = async (id) => {
    if (!window.confirm('Are you sure you want to delete this event?')) return;

    setMessage('');

    // Retrieve the token from localStorage
    const token = localStorage.getItem('token');

    if (!token) {
      setMessage('You must be logged in to perform this action.');
      return;
    }

    try {
      const res = await fetch(`${BASE_URL}${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token here
        },
      });

      const data = await res.json();

      if (res.ok) {
        setMessage('Event deleted successfully.');
        fetchEvents(activeTab);
      } else {
        setMessage(data.error || 'Failed to delete event.');
      }
    } catch (err) {
      console.error('Error deleting event:', err);
      setMessage('An error occurred. Please try again.');
    }
  };

  /**
   * Cancel the editing process and reset the form.
   */
  const handleCancelEdit = () => {
    setIsEditing(false);
    setCurrentEventId(null);
    setFormData({
      title: '',
      description: '',
      startDate: null,
      endDate: null,
      image: '',
    });
    setMessage('');
  };

  return (
    <div className="admin-container bg-gray-900 min-h-screen p-8 text-white">
      <h2 className="text-4xl font-semibold mb-6">Admin Dashboard</h2>

      {message && <p className="mb-4 text-orange-500">{message}</p>}

      {/* Tabs for Ongoing, Previous, and Upcoming Events */}
      <div className="mb-6 flex space-x-4">
        {['Ongoing', 'Previous', 'Upcoming'].map((category) => (
          <button
            key={category}
            className={`px-6 py-2 rounded-full font-medium ${
              activeTab === category
                ? 'bg-orange-500 text-white'
                : 'bg-gray-700 text-gray-300 hover:bg-orange-600 hover:text-white'
            } transition-colors duration-300`}
            onClick={() => setActiveTab(category)}
          >
            {category} Events
          </button>
        ))}
      </div>

      {/* Event Form */}
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-8">
        <h3 className="text-2xl font-semibold mb-4">
          {isEditing ? 'Edit Event' : 'Add New Event'}
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Title */}
          <div>
            <label className="block mb-1">Title</label>
            <input
              type="text"
              name="title"
              className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-orange-500"
              value={formData.title}
              onChange={handleChange}
              placeholder="Event Title"
              required
            />
          </div>

          {/* Description */}
          <div>
            <label className="block mb-1">Description</label>
            <textarea
              name="description"
              className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-orange-500"
              rows="4"
              value={formData.description}
              onChange={handleChange}
              placeholder="Event Description"
              required
            ></textarea>
          </div>

          {/* Dates */}
          <div className="flex space-x-4">
            {/* Start Date */}
            <div className="w-1/2">
              <label className="block mb-1">Start Date</label>
              <DatePicker
                selected={formData.startDate}
                onChange={handleStartDateChange}
                dateFormat="dd/MM/yyyy"
                className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholderText="dd/mm/yyyy"
                required
              />
            </div>
            {/* End Date */}
            <div className="w-1/2">
              <label className="block mb-1">End Date</label>
              <DatePicker
                selected={formData.endDate}
                onChange={handleEndDateChange}
                dateFormat="dd/MM/yyyy"
                className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholderText="dd/mm/yyyy"
                required
              />
            </div>
          </div>

          {/* Image URL */}
          <div>
            <label className="block mb-1">Image URL</label>
            <input
              type="url"
              name="image"
              className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-orange-500"
              value={formData.image}
              onChange={(e) => setFormData({ ...formData, image: e.target.value })}
              placeholder="https://example.com/image.jpg"
              required
            />
          </div>

          {/* Buttons */}
          <div className="flex space-x-4">
            <button
              type="submit"
              className={`px-6 py-2 rounded-full font-medium ${
                isEditing
                  ? 'bg-blue-500 hover:bg-blue-600'
                  : 'bg-green-500 hover:bg-green-600'
              } transition-colors duration-300`}
            >
              {isEditing ? 'Update Event' : 'Add Event'}
            </button>
            {isEditing && (
              <button
                type="button"
                onClick={handleCancelEdit}
                className="px-6 py-2 rounded-full font-medium bg-red-500 hover:bg-red-600 transition-colors duration-300"
              >
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>

      {/* Events List */}
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold mb-4">Manage Events</h3>
        {loading ? (
          <p className="text-gray-400">Loading events...</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-gray-700">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">Title</th>
                  <th className="py-2 px-4 border-b">Description</th>
                  <th className="py-2 px-4 border-b">Start Date</th>
                  <th className="py-2 px-4 border-b">End Date</th>
                  <th className="py-2 px-4 border-b">Image</th>
                  <th className="py-2 px-4 border-b">Category</th>
                  <th className="py-2 px-4 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {events.map((event) => (
                  <tr key={event._id} className="hover:bg-gray-600">
                    <td className="py-2 px-4 border-b">{event.title || 'N/A'}</td>
                    <td className="py-2 px-4 border-b">
                      {event.description.length > 50
                        ? `${event.description.substring(0, 50)}...`
                        : event.description || 'N/A'}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {event.startDate
                        ? format(new Date(event.startDate), 'dd/MM/yyyy')
                        : 'N/A'}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {event.endDate
                        ? format(new Date(event.endDate), 'dd/MM/yyyy')
                        : 'N/A'}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {event.image ? (
                        <img
                          src={event.image}
                          alt={event.title}
                          className="w-16 h-16 object-cover rounded"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://via.placeholder.com/64?text=No+Image';
                          }}
                        />
                      ) : (
                        'No Image'
                      )}
                    </td>
                    <td className="py-2 px-4 border-b">{event.category || 'N/A'}</td>
                    <td className="py-2 px-4 border-b space-x-2">
                      <button
                        onClick={() => handleEditClick(event)}
                        className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded transition-colors duration-300"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteEvent(event._id)}
                        className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded transition-colors duration-300"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
                {events.length === 0 && (
                  <tr>
                    <td colSpan="7" className="py-4 px-4 text-center text-gray-400">
                      No events available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
