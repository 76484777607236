// src/App.js

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import ServerInfo from './components/ServerInfo';
import Events from './components/Events';
import Store from './components/Store';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Admin from './components/Admin';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component
import './App.css'; // Optional: Include your custom styles

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check for token in localStorage on app load
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  const handleAuth = (status) => {
    setIsAuthenticated(status);
  };

  return (
    <Router>
      <Header
        isAuthenticated={isAuthenticated}
        onLogout={() => setIsAuthenticated(false)}
      />
      <div className="pt-20"> {/* Padding to account for fixed header */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <ServerInfo />
                <Events />
                <Store />
                <Contact />
              </>
            }
          />
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/admin" replace />
              ) : (
                <Login onAuth={handleAuth} />
              )
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

// NotFound Component for undefined routes
const NotFound = () => (
  <div className="flex items-center justify-center h-screen text-white">
    <h2 className="text-3xl">404 - Page Not Found</h2>
  </div>
);

export default App;
