// src/components/Store.js

import React from 'react';
import './Store.css';

const Store = () => {
  return (
    <section className="store-section" id="store">
      <h3>Connect with Us</h3>
      <p className="store-description">
        Check out our store for exclusive items and join our Discord to stay updated!
      </p>
      <div className="store-links">
        <a href="https://shop.orangeevents.net" target="_blank" rel="noopener noreferrer" className="store-link">
          <i className="fas fa-store store-icon"></i> Visit Our Store
        </a>
        <a href="https://discord.orangeevents.net" target="_blank" rel="noopener noreferrer" className="discord-link">
          <i className="fab fa-discord discord-icon"></i> Join Our Discord
        </a>
      </div>
    </section>
  );
};

export default Store;
